<template>
  <div class="resource pageInit">
    <h1 class="pageTitle textColor">Resources</h1>
    <div class="searchContiner">
      <searchBox style="margin-right: 20px"
                 :show-go="true"
                 @clickGoBtn="clickGoBtn"
                 ref="refSearch"
      ></searchBox>
    </div>
    <!--表格-->
    <div class="lists">
      <div class="list" v-for="(item, index) in lists" :key="'list' + index">
        <el-row class="listContainer">
          <!--<p class="docIcon">-->
          <!--  <img v-if="item.img" :src="item.img" alt=""/>-->
          <!--  <i v-else class="iconfont icon-pdf" style="font-size: 30px;color:gray"></i>-->
          <!--</p>-->
          <el-col :xs="24" :md="12" :lg="15" class="docName">
            <img v-if="client=='inpixon'" src="@/assets/imgs/inpixon/resource/file_icon.png"/>
            <img v-if="client=='Aruba'" src="@/assets/imgs/Aruba/resource/fileBlue.png"/>
            <span>{{ item.title }}</span>
          </el-col>
          <el-col :xs="24" :md="12" :lg="9" class="viewBtn">
            <button class="x-btn" @click="toggleBriefcase(item.id)"
                    v-if="(item.isBriefcase||item.isBriefcase==false)&&item.isBriefcase!==''"
                    :class="{borderBtn:!item.isBriefcase}" style="margin-right: 20px;">
              {{ item.isBriefcase ? 'Add to Briefcase' : 'Remove from Briefcase' }}
            </button>
            <button class="x-btn" @click="clickView(item)">View</button>
          </el-col>
        </el-row>
      </div>
    </div>
    <!--分页-->
    <div class="paginationContainer">
      <el-pagination
          v-model:currentPage="currentPage"
          v-model:page-size="pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          background
          :page-sizes="[5, 10, 15, 20,50]"
          layout="prev, pager, next"
          :total="total"
          class="pagination mt-4"
      >
      </el-pagination>
    </div>

  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted, toRaw,
} from "vue";
import searchBox from "@/components/tools/search.vue";
import axios from "@/api/http";
import elq from '@/utils/arubaElq.js';
import {ElLoading, ElMessageBox} from "element-plus";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "Rescoure",
  components: {
    searchBox,
  },
  setup() {
    const router = useRouter();
    const client = localStorage.getItem("eventClient");
    const refSearch = ref(null);//用来查询的时候获取searhBox组件的input的值
    const data = reactive({
      select: '',
      options: [
        {label: "All Tags", value: 1},
      ],
      lists: [
        // {
        //   img: require("../../assets/imgs/icon.jpg"),
        //   name: "Virtual Vendor show overview",
        //   id: 1,
        // },
        // {
        //   img: require("../../assets/imgs/icon.jpg"),
        //   name: "MC Event Tutoral",
        //   id: 1,
        // },
        // {img: require("../../assets/imgs/icon.jpg"), name: "Lorem", id: 1},
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0
    });
    const formatData = (data) => {
      let arr = [];
      data.map(item => {
        let obj = {};
        obj.img = item.img;
        obj.name = item.subject;
        obj.id = item.id;
        obj.link = item.link;
        arr.push(obj);
      })
      return arr
    };
    const getData = (keyword, pageSize, index) => {
      let refKeyword = refSearch.value.input1.trim();
      const loading = ElLoading.service({
        lock: true,
      })
      axios.request({
        method: 'PATCH',
        url: '/virtual/resource',
        data: {
          meetingId: 'a7yyxczuty0qpjo2393gmyad',
          pageSize: pageSize || data.pageSize,
          index: index || data.currentPage - 1,
          keyword: keyword || refKeyword
          // meetingId: localStorage.eventsMeetingId,
        }
      }).then(res => {
        loading.close();
        console.log('000');
        console.log(res)
        console.log(res.data.contents)
        data.total = res.data.total;
        data.lists = res.data.contents;
      }).catch(err => {
        loading.close();
        console.log(err);
      })
    }
    const clickView = (i) => {
      elq.elqActivityForm('', "View Resource - General", i.id);
      console.log(i);
      if (i.link != '') {
        if (i.link.indexOf("http") >= 0) {
          window.open(i.link, '_blank');
        } else {
          window.open('/files/' + i.link, '_blank');
        }
      }
    }
    const clickGoBtn = (keyword) => {
      data.currentPage = 1;
      getData(keyword)
    }
    const toggleBriefcase = (id) => {
      // console.log("====lists =====",data.lists)
      console.log("==== id=====", id)
      let length = data.lists.length;
      for (let i = 0; i < length; i++) {
        let item = data.lists[i];
        if (item.id == id) {
          // item.isBriefcase = !item.isBriefcase;
          let arr = [];
          arr.push(id);
          if (item.isBriefcase) {
            elq.elqActivityForm('', "Add to Briefcase - General", id);
            const loading = ElLoading.service({
              lock: true,
            })
            axios.request({
              method: 'POST',
              url: '/virtual/briefcase',
              data: {
                contentId: arr
              }
            }).then(res => {
              console.log('res:', res);
              ElMessageBox.confirm(
                  'Item added to your Virtual Briefcase.',
                  'Done and Done',
                  {
                    confirmButtonText: 'GO There Now',
                    cancelButtonText: 'OK',
                    type: 'info',
                    closeOnClickModal:false
                  }
              ).then(res=>{
                router.push({name:'VirtualBriefcase'})
                item.isBriefcase = !item.isBriefcase;
                loading.close();
              }).catch(()=>{
                item.isBriefcase = !item.isBriefcase;
                loading.close();
              })

            }).catch(err => {
              console.log(err);
              loading.close();
            })
          } else {
            ElMessageBox.confirm(
                'Are you sure you want to remove this item? You may add them again later from their respective events/meetings.',
                'Think Twice',
                {
                  confirmButtonText: 'Remove',
                  cancelButtonText: 'Cancel',
                  type: 'warning',
                  closeOnClickModal:false
                }
            ).then(()=>{
              const loading = ElLoading.service({
                lock: true,
              })
              axios.request({
                method: 'DELETE',
                url: '/virtual/briefcase',
                data: {
                  contentId: arr
                }
              }).then(res => {
                console.log('res:', res);
                item.isBriefcase = !item.isBriefcase;
                loading.close();
              }).catch(err => {
                console.log(err);
                loading.close();
              })
            })

          }
          break
        }
      }
    }
    const handleSizeChange = (page) => {
      getData();
    }
    const handleCurrentChange = () => {
      getData();
    }
    onBeforeMount(() => {
    });
    onMounted(() => {
      getData();
    });
    return {
      ...toRefs(data),
      client,
      router,
      refSearch,
      getData,
      clickView,
      clickGoBtn,
      toggleBriefcase,
      handleSizeChange,
      handleCurrentChange
    };
  },
});
</script>
<style scoped lang='scss'>
.resource {
  padding-right: 20px;

  .searchContiner {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
  }

  .filterContainer {
    /* display: flex;
    align-items: center; */
    /* justify-content: flex-end; */
    margin: 20px 0 10px 0;

    .searchContiner {
      display: flex;
      align-items: center;
      margin-right: 15px;
      justify-content: flex-end;

      .go-btn {
        background-color: transparent;
        color: #fff;
        border-radius: 50%;
        border: 1px solid #fff;
        height: 35px;
        width: 35px;
      }
    }
  }

  .lists {
    padding: 0px 0 20px 0;
    border-top: 1px solid #fff;

    .list:nth-child(odd) {
      /* background: #f2f3f5; */
    }

    .list:nth-child(even) {
      /* background: #fff; */
    }

    .list {
      .listContainer {
        //display: flex;
        //align-items: center;
        //padding: 15px;
        min-height: 40px;

        .docIcon {
          width: 200px;
          text-align: center;
        }

        .docName {
          display: flex;
          align-items: center;
          flex: 1;
          font-size: 16px;

          span {
            padding: 0 10px;
          }
        }

        .viewBtn {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          button {
            background-color: #2dccd3;
            min-width: 120px;
            border: 0;
            border-radius: 15px;
            height: 30px;
            font-family: OpenSans-Semibold;
            cursor: pointer;
          }
        }
      }
    }
  }

  .paginationContainer {
    margin: 30px auto;

    .pagination {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
